<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="font-weight-black">
      <span>
        Anexos
        <v-progress-circular
          v-if="carregandoRemover || carregandoAdicionar || carregandoBuscar"
          indeterminate
          :size="15"
          :width="2"
          color="primary"
          class="ml-2"
        />
      </span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row no-gutters>
        <v-col class="no-gutters">
          <i
            v-if="Object.keys(anexos).length <= 0 && !carregandoBuscar && !carregandoAdicionar"
            class="grey--text"
          >
            <small>Nenhum anexo adicionado</small>
          </i>
          <v-tooltip
            v-for="(anexo, key) in anexos"
            v-else
            :key="key"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="my-1 mr-1"
                close
                label
                close-icon="mdi-delete"
                outlined
                v-bind="attrs"
                v-on="on"
                @click="download(anexo)"
                @click:close="remover(anexo)"
              >
                {{ anexo.nome_original || anexo.nome_arquivo | stringLimit(10) }}
              </v-chip>
            </template>
            <span>{{ anexo.nome_original || anexo.nome_arquivo }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="!seeOnly">
        <v-col class="no-gutters">
          <v-btn
            outlined
            small
            color="success"
            :disabled="!ocorrenciaId || carregandoBuscar || carregandoAdicionar || carregandoRemover"
            @click="mostrarDialogInserir = true"
          >
            <v-icon
              left
              v-text="'mdi-plus'"
            />
            Adicionar
          </v-btn>

          <v-dialog
            :value="mostrarDialogInserir"
            content-class="dialog-ocorrencias-tabs-anexos"
            @click:outside="fecharDialog"
          >
            <v-card>
              <v-toolbar
                color="grey lighten-3"
                flat
                dense
              >
                <v-toolbar-title>
                  Adicionar Anexo
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                  <v-btn
                    text
                    small
                    @click="fecharDialog"
                  >
                    <v-icon v-text="'mdi-close'" />
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>

              <v-card-text class="pt-7">
                <v-file-input
                  v-model="anexoInserir"
                  show-size
                  label="Anexo"
                  dense
                  outlined
                  truncate-length="40"
                  hide-details="auto"
                />
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <btn-salvar
                  outlined
                  :carregando="carregandoAdicionar"
                  @click="adicionar"
                />
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import ocorrenciasApi from '@/api/ocorrencias'

  export default {

    props: {
      ocorrenciaId: {
        type: Number,
        default: 0,
      },
      seeOnly: {
      type: Boolean,
      default:false,
    },
    },

    data () {
      return {
        mostrarDialogInserir: false,
        carregandoBuscar: false,
        carregandoAdicionar: false,
        carregandoRemover: false,
        anexos: [],
        anexoInserir: null,
        caminhoAnexos: process.env.VUE_APP_URL_SERVIDOR + 'storage/anexos_ocorrencia/',
      }
    },

    mounted () {
      this.buscar()
    },

    methods: {
      async buscar () {
        if (!this.ocorrenciaId) return

        try {
          this.carregandoBuscar = true
          const resposta = await ocorrenciasApi.buscarAnexos(this.ocorrenciaId)
          this.anexos = resposta.data
        } catch (e) {
          console.log(e)
        } finally {
          this.carregandoBuscar = false
        }
      },

      async adicionar () {
        try {
          this.carregandoAdicionar = true

          const formData = new FormData()
          formData.append('ocorrencia_id', this.ocorrenciaId)
          formData.append('arquivo', this.anexoInserir)
          formData.append('nome_arquivo', this.anexoInserir.name)

          await ocorrenciasApi.adicionarAnexo(formData)

          this.fecharDialog()
          this.buscar()
        } catch (e) {
          console.log(e)
        } finally {
          this.carregandoAdicionar = false
        }
      },

      async download (anexo) {
        const url = this.caminhoAnexos + anexo.nome_arquivo
        const win = window.open(url, '_blank')
        win.focus()
      },

      async remover (anexo) {
        try {
          this.carregandoRemover = true
          await ocorrenciasApi.removerAnexo(anexo.id)
          this.buscar()
        } catch (e) {
          console.log(e)
        } finally {
          this.carregandoRemover = false
        }
      },

      fecharDialog () {
        this.mostrarDialogInserir = false
        this.anexoInserir = null
      },

    },
  }
</script>

<style lang="css">
  .dialog-ocorrencias-tabs-anexos {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    .dialog-ocorrencias-tabs-anexos {
      width: 40% !important;
    }
  }
</style>
